<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      persistent
      max-width="550"
    >
      <v-card class="pa-2">
        <v-container class="pa-0">
          <v-card-title class="text-center">
            <span class="title">You have changed the terms and conditions settings for your company,
              shall all users (viewers, kickboxers and experts) in your company agree on the
              new terms and conditions?</span>
          </v-card-title>
        </v-container>
        <v-card-actions>
          <v-spacer />

          <app-button @click="yesOption">
            Yes
          </app-button>
          <app-button
            secondary
            class="ml-4"
            @click="noOption"
          >
            No
          </app-button>
          <app-button
            secondary
            cancel
            class="ml-4"
            @click="close"
          >
            Cancel
          </app-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['show'],
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    close() {
      this.$emit('close');
    },
    yesOption() {
      this.$emit('yes-option');
      this.close();
    },
    noOption() {
      this.$emit('no-option');
      this.close();
    }
  }
};
</script>

<style scoped>
  ::v-deep .title {
    font-size: 1.1rem !important;
    word-break: break-word;
    text-align: left;
  }
</style>
