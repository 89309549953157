<template>
  <v-container class="default-custom-base-container">
    <v-row>
      <v-col
        cols="12"
        md="10"
        lg="8"
        xl="6"
      >
        <v-card class="pt-2 px-4">
          <v-card-text>
            <label>
              {{ title }}
            </label>
            <v-radio-group
              v-model="defaultSelected"
              @change="$emit('isDefaultSelected', defaultSelected)"
            >
              <v-radio
                :label="defaultOptionLabel"
                :value="true"
                color="primary"
              />
              <v-radio
                :label="customOptionLabel"
                :value="false"
                color="primary"
              />
            </v-radio-group>

            <slot />

            <div>
              <app-button
                :loading="loadingButton"
                class="mt-6"
                @click="save"
              >
                Save
              </app-button>
            </div>
            <v-col class="mt-4">
              <label class="body-1">* Mandatory field</label>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DefaultCustomBase',
  props: {
    title: {
      type: String,
      default: ''
    },
    isDefault: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      defaultSelected: true,
      isDefaultActive: true,
      loadingButton: false,
    };
  },
  computed: {
    ...mapGetters([
      'company'
    ]),
    defaultOptionLabel() {
      return this.isDefaultActive ? 'Use default (currently active)' : 'Use default';
    },
    customOptionLabel() {
      return !this.isDefaultActive ? 'Use custom (currently active)' : 'Use custom';
    }
  },
  created() {
    if (!this.isDefault) {
      this.defaultSelected = false;
      this.isDefaultActive = false;
    }
  },
  methods: {
    save() {
      this.$emit('save');
      this.isDefaultActive = this.defaultSelected;
    }
  }
};
</script>
