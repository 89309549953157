import Parse from '@kickbox/common-util/src/parse';
import store from '@/store';

export default {
  async getLanguages() {
    const languageObjects = await new Parse.Query('Language')
      .containedIn('key', store.getters.company.parseObject.get('enabledLanguages'))
      .find();

    return languageObjects.map((lang) => ({
      key: lang.get('key'),
      name: lang.get('name')
    }));
  }
};
