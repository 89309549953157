import Parse from '@kickbox/common-util/src/parse';
import store from '@/store';
import translationService from './translationService';

export default {
  async save(languages, urls, useDefaultTerms, warnUsers) {
    const terms = (await this.getTerms()) || [];

    if (terms.length && useDefaultTerms) {
      terms.forEach((term) => {
        term.parseObject.save({
          active: false
        });
      });
    } else if (!useDefaultTerms) {
      Object.entries(urls).forEach(([key, value]) => {
        let termsConditions = terms.find((term) => term.language === key);
        let termValues;
        if (!termsConditions && !useDefaultTerms) {
          termsConditions = new Parse.Object('TermsConditions');
          termValues = {
            active: true,
            language: key,
            url: value,
            company: store.getters.company.parseObject
          };
          termsConditions.save(termValues);
        } else if (termsConditions && !useDefaultTerms) {
          termValues = {
            active: true,
            url: value,
          };
          termsConditions.parseObject.save(termValues);
        }
      });
    }

    if (warnUsers) {
      Parse.Cloud.run('resetShowTermsConditions');
    }
  },
  async getTerms() {
    const terms = await new Parse.Query('TermsConditions')
      .equalTo('company', store.getters.company.parseObject)
      .find();
    return terms.map((t) => ({
      language: t.get('language'),
      active: t.get('active'),
      url: t.get('url'),
      parseObject: t
    }));
  },
  async getTermTexts(companyLanguages) {
    const termDescription = new Parse.Query('I18n')
      .equalTo('key', 'signup_login.terms_and_conditions_description');
    const termLinkPhrase = new Parse.Query('I18n')
      .equalTo('key', 'signup_login.terms_and_conditions');
    const termLinks = new Parse.Query('I18n')
      .equalTo('key', 'footer_bar.legal_aspects.link');
    // eslint-disable-next-line new-cap
    const terms = await new Parse.Query.or(termDescription, termLinkPhrase, termLinks).find();
    const formattedTerms = terms.map((t) => translationService
      .getAvailableTranslation(t, companyLanguages));
    return {
      termLinks: formattedTerms[0],
      termDescriptions: formattedTerms[1],
      termLinkTexts: formattedTerms[2],
    };
  }
};
