<template>
  <v-container>
    <term-confirm-dialog
      v-if="dialogConfirmTerms"
      :show="dialogConfirmTerms"
      @close="dialogConfirmTerms = false"
      @yes-option="save"
      @no-option="saveWithoutWarnUsers"
    />
    <default-custom-base
      v-if="terms"
      :title="title"
      :is-default="isDefaultActive"
      @isDefaultSelected="isDefault = $event"
      @save="openDialogConfirmTerms"
    >
      <div
        v-for="lang in availableLanguages"
        :key="`terms-${lang.key}`"
        class="mt-2 py-2"
      >
        <label class="d-block mb-3 font-weight-bold">{{ lang.name }}</label>
        <label class="body-2">{{ terms.termDescriptions[lang.key] }}
          <a
            :href="isDefault ? terms.termLinks[lang.key] : agreementLinks[lang.key]"
            target="_blank"
            class="text-decoration-none"
          >{{ terms.termLinkTexts[lang.key] }}
          </a>
        </label>
        <v-text-field
          v-if="!isDefault"
          :ref="`agreementLinks[${lang.key}]`"
          v-model="agreementLinks[lang.key]"
          :rules="[lang.key === companyLanguage ?
            value => !!value || 'The field is required.' : true]"
          :label="lang.key === companyLanguage ? 'Link*' : 'Link'"
          clearable
          placeholder="https://www.your-page.com"
          class="mt-4"
          required
        />
        <v-divider
          v-if="isDefault"
          class="mt-5"
        />
      </div>
    </default-custom-base>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import LanguageService from '@/services/languageService';
import TermsConditionsService from '@/services/termsConditionsService';
import DefaultCustomBase from '@/components/widgets/DefaultCustomBase';
import TermConfirmDialog from './TermConfirmDialog';

export default {
  components: {
    TermConfirmDialog,
    DefaultCustomBase
  },
  data() {
    return {
      isDefaultActive: true,
      isDefault: false,
      availableLanguages: [],
      terms: null,
      agreementLinks: {},
      dialogConfirmTerms: false,
      title: 'The terms and conditions will apply to all users (viewers, kickboxers, etc)'
          + ' that use the platform within your company'
    };
  },
  computed: {
    ...mapGetters([
      'company'
    ]),
    companyLanguage() {
      return this.company.language;
    }
  },
  async created() {
    this.availableLanguages = await LanguageService.getLanguages();

    const allTerms = await TermsConditionsService.getTerms();
    allTerms.forEach((t) => {
      this.$set(this.agreementLinks, t.language, t.url);
    });

    const isCustom = allTerms.some((t) => t.active);
    if (isCustom) {
      this.isDefaultActive = false;
    } else {
      this.isDefault = true;
    }
    this.terms = await TermsConditionsService.getTermTexts(this.availableLanguages);
  },
  methods: {
    openDialogConfirmTerms() {
      if (!this.validateMandatoryField() && !this.isDefault) {
        this.$store.dispatch('showSnackBar', { text: 'Please, fill in all the mandatory fields.' });
      } else {
        this.dialogConfirmTerms = true;
      }
    },
    saveWithoutWarnUsers() {
      this.save(false);
    },
    async save(warnUsers = true) {
      await TermsConditionsService
        .save(this.availableLanguages, this.agreementLinks, this.isDefault, warnUsers);
      this.$store.dispatch('showSnackBar', { text: 'The terms & conditions have been saved.' });
    },
    validateMandatoryField() {
      const f = `agreementLinks[${this.companyLanguage}]`;
      return this.$refs[f] && this.$refs[f][0] && this.$refs[f][0].validate(true);
    },
  }
};
</script>
